import React, { Component } from 'react'
import { graphql } from 'gatsby'
import injectSheet from 'react-jss'
import Layout from '../layout'
import Page from '../Page/Page'
import BlogList from '../BlogList/BlogList'
import BlogCategories from '../BlogList/BlogCategories'
import Pagination from '../BlogList/Pagination'

interface Props {
  classes?: any
  location?: any
  pageContext: any
  data: {
    datoCmsPost: HQ.Post
    allDatoCmsPost: HQ.Posts
    allDatoCmsCategory: HQ.Categories
  }
}

class Test extends Component<Props> {
  render() {
    const {
      location,
      pageContext: { pageNumber, numberOfPages, nextPagePath, previousPagePath },
      data: {
        datoCmsPost: featuredPost,
        allDatoCmsPost: { nodes: posts },
        allDatoCmsCategory: { nodes: categories },
      },
      classes: c,
    } = this.props
    return (
      <Layout location={location}>
        <Page title={'Blog'} subtitle={'News, tips, highlights and other updates from the team at HQ.'}>
          <div className={c.BlogPageContainer}>
            <BlogCategories data={categories} />
            <BlogList data={posts} featured={featuredPost} />
            {numberOfPages > 1 && (
              <Pagination
                {...{
                  pageNumber,
                  numberOfPages,
                  nextPagePath,
                  previousPagePath,
                }}
              />
            )}
          </div>
        </Page>
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogTestPage($limit: Int, $skip: Int) {
    datoCmsPost(featured: { eq: true }) {
      author {
        avatar {
          fixed(height: 48, width: 48) {
            src
          }
        }
        name
      }
      meta {
        publishedAt(formatString: "MMM D, YYYY")
      }
      slug
      title
      description
      category {
        color {
          hex
        }
        title
      }
      cover {
        url
      }
    }
    allDatoCmsPost(filter: { featured: { ne: true } }, limit: $limit, skip: $skip) {
      nodes {
        id
        title
        description
        slug
        cover {
          url
        }
        meta {
          publishedAt(formatString: "MMM D, YYYY")
        }
        category {
          color {
            hex
          }
          title
        }
        author {
          avatar {
            fixed(height: 48, width: 48) {
              src
            }
          }
          name
        }
      }
    }
    allDatoCmsCategory {
      nodes {
        id
        title
      }
    }
  }
`

const styles = {
  BlogPageContainer: {
    paddingBottom: '64px',
  },
}

export default injectSheet(styles)(Test)
