import React, { Component } from 'react'
import injectSheet from 'react-jss'
import Select from 'react-select'
import { Link, navigate } from 'gatsby'

interface Props {
  data: HQ.Category[]
  currentPage: string
  classes: any
}

const normalizeData = (data: any[]) => {
  const allCategoriesLink = [
    {
      id: 'all-categories',
      title: 'All categories',
    },
  ]

  return [...allCategoriesLink, ...data].map((item) => ({
    id: item.id,
    value: item.title,
    label: item.title,
  }))
}

const findDefaultSelectMenu = (data: any[], page: string) => {
  const result = data.filter((item) => item.value === page)[0]
  return result
}

class BlogCategories extends Component<Props> {
  handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'All categories') {
      navigate('/blog')
    } else {
      navigate(`/category/${selectedOption.value.toLowerCase()}/`)
    }
  }

  render() {
    const { data, currentPage, classes: c } = this.props
    return (
      <div className={c.CategoryListContainer}>
        <div className={c.DesktopBlock}>
          <Link className={c.CategoryLink} to={`/blog`}>
            <span className={c.CategoryBlock} style={{ color: !currentPage ? '#579dbc' : '' }}>
              All categories
            </span>
          </Link>
          {data.map(({ title, id }) => (
            <Link className={c.CategoryLink} to={`/category/${title.toLowerCase()}`} key={id}>
              <span className={c.CategoryBlock} style={{ color: currentPage === title ? '#579dbc' : '' }}>
                {title}
              </span>
            </Link>
          ))}
        </div>
        <Select
          className={c.MobileSelect}
          onChange={this.handleChange}
          options={normalizeData(data)}
          defaultValue={findDefaultSelectMenu(normalizeData(data), currentPage)}
        />
      </div>
    )
  }
}

const styles = {
  DesktopBlock: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
    margin: '48px 0 32px 0',
    padding: '18px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  CategoryLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  CategoryListContainer: {},
  CategoryBlock: {
    fontSize: '18px',
    lineHeight: '21px',

    '&:hover': {
      color: '#579dbc',
      cursor: 'pointer',
    },
  },
  MobileSelect: {
    display: 'none',
    margin: '32px 0',
    width: '100%',
    '& > div': {
      padding: '12px 20px',
      border: 'none',
      borderRadius: '8px',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',

      '& span': {
        display: 'none',
      },
    },
    '& svg': {
      color: '#000',
      '&:hover': {
        color: '#000',
      },
    },

    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
}

export default injectSheet(styles)(BlogCategories)
