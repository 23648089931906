import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Link } from 'gatsby'
import AuthorBlock from './AuthorBlock'

interface Props {
  data: HQ.Post
  classes: any
}

class FeaturedPost extends Component<Props> {
  render() {
    const {
      data: {
        title,
        slug,
        description,
        category,
        author,
        meta: { publishedAt },
      },
      classes: c,
    } = this.props
    return (
      <Link to={`blog/${slug}`} className={c.FeaturedPostLink}>
        <div className={c.FeaturedBlock}>
          <div className={c.PostContent}>
            <div>
              <h5 className={c.FeaturedTitle}>{title}</h5>
              <p className={c.FeaturedDescription}>{description}</p>
            </div>
            <div className={c.FooterFeatured}>
              <AuthorBlock data={author} date={publishedAt} />
              <div className={c.FooterCategoryFeatured}>
                <span className={c.FeaturedCategory} style={{ backgroundColor: category.color.hex }}>
                  {category.title}
                </span>
                <span className={c.FeaturedCategory} style={{ backgroundColor: '#55B69E' }}>
                  Featured
                </span>
              </div>
            </div>
          </div>
          <div className={c.FeaturedBlockImage}>
            <div className={c.ImageCategoryMobile}>
              <span className={c.FeaturedCategory} style={{ backgroundColor: category.color.hex }}>
                {category.title}
              </span>
              <span className={c.FeaturedCategory} style={{ backgroundColor: '#55B69E' }}>
                Featured
              </span>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

const styles = {
  FeaturedPostLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  FeaturedBlock: {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
    marginBottom: '32px',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      flexDirection: 'column-reverse',
    },
  },
  FeaturedCategory: {
    padding: '4px 10px',
    borderRadius: '6px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#fff',
    marginRight: '8px',
  },
  FeaturedBlockImage: {
    backgroundImage: (props: any) => `url(${props.data.cover.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative',
    flex: 2,
    minHeight: '360px',
    '@media (max-width: 1024px)': {
      minHeight: '250px',
    },
  },
  PostContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '48px 56px',
    flex: 3,
    '@media (max-width: 1024px)': {
      padding: '24px',
    },
  },
  FeaturedTitle: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '140%',
    margin: '0',
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  FeaturedDescription: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '160%',
    color: '#999',
    paddingLeft: '5px',
    margin: '32px 0',
  },
  FooterFeatured: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  FooterCategoryFeatured: {
    alignSelf: 'flex-end',
    paddingBottom: '15px',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  ImageCategoryMobile: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'absolute',
      left: '24px',
      top: '24px',
    },
  },
}

export default injectSheet(styles)(FeaturedPost)
