import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Link } from 'gatsby'
import AuthorBlock from './AuthorBlock'

interface Props {
  data: HQ.Post
  classes: any
}

class BlogListPost extends Component<Props> {
  render() {
    const {
      data: {
        title,
        description,
        author,
        slug,
        meta: { publishedAt },
        category: {
          title: categoryName,
          color: { hex },
        },
      },
      classes: c,
    } = this.props
    return (
      <div>
        <div className={c.BlogPostListContainer}>
          <Link to={`/blog/${slug}`} className={c.PostLink}>
            <div className={c.PostCover}>
              <div className={c.ImageCategoryBlockMobile}>
                <span className={c.BlogCategory} style={{ backgroundColor: hex }}>
                  {categoryName}
                </span>
              </div>
            </div>
            <div className={c.PostBlockContent}>
              <div>
                <h3 className={c.PostTitle}>{title}</h3>
                <p className={c.PostDescription}>{description}</p>
              </div>
              <div className={c.FooterBlog}>
                <AuthorBlock data={author} date={publishedAt} />
                <div className={c.FooterCategoryBlock}>
                  <span className={c.BlogCategory} style={{ backgroundColor: hex }}>
                    {categoryName}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

const styles = {
  PostLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  BlogPostListContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: '8px',
    margin: '16px',
    overflow: 'hidden',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
  },
  PostCover: {
    position: 'relative',
    width: '100%',
    height: '300px',
    backgroundImage: (props: any) => `url(${props.data.cover.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '@media (max-width: 1024px)': {
      height: '210px',
    },
  },
  PostBlockContent: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: '400px',
    '@media (max-width: 1024px)': {
      padding: '24px',
      minHeight: 'initial',
    },
  },
  PostTitle: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '140%',
    color: '#333',
    margin: 0,
    paddingBottom: '29px',
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '28px',
      paddingBottom: '16px',
    },
  },
  PostDescription: {
    fontSize: '16px',
    lineHeight: '160%',
    color: '#999',
    fontWeight: 300,
    '@media (max-width: 1024px)': {
      fontSize: '14px',
      marginBottom: '16px',
    },
  },
  FooterBlog: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  BlogCategory: {
    padding: '4px 10px',
    borderRadius: '6px',
    marginRight: '16px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#fff',
  },
  FooterCategoryBlock: {
    alignSelf: 'flex-end',
    paddingBottom: '15px',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  ImageCategoryBlockMobile: {
    display: 'none',
    position: 'absolute',
    top: '24px',
    left: '24px',

    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
}

export default injectSheet(styles)(BlogListPost)
