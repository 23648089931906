import React, { Component } from 'react'
import injectSheet from 'react-jss'
import BlogListPost from './BlogListPost'
import FeaturedPost from './FeaturedPost'

interface Props {
  data: HQ.Post[]
  featured: HQ.Post
  classes: any
}

class BlogList extends Component<Props> {
  render() {
    const { data, featured, classes: c } = this.props
    return (
      <div>
        {!!featured && <FeaturedPost data={featured} />}
        <div className={c.PostListContainer}>
          {data.map((item) => (
            <BlogListPost key={item.id} data={item} />
          ))}
          {!data.length && <p className={c.MissedPosts}>You don't have posts</p>}
        </div>
      </div>
    )
  }
}

const styles = {
  PostListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -16px',

    '& > div': {
      flex: '0 50%',
      maxWidth: '50%',
      display: 'flex',
      '@media (max-width: 1024px)': {
        flex: '0 100%',
        maxWidth: '100%',
      },
    },
  },
  MissedPosts: {
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '42px',
    color: '#999',
    textAlign: 'center',
    width: '100%',
    padding: '78px 24px ',
  },
  '@media (min-width: 1480px)': {
    PostListContainer: {
      '& > div': {
        flex: '0 33%',
        maxWidth: '33%',
        display: 'flex',
      },
    },
  },
}

export default injectSheet(styles)(BlogList)
