import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Link, navigate } from 'gatsby'
import arrowLeft from '../../images/arrow-left.png'
import arrowRight from '../../images/arrow-right.png'

interface Props {
  pageNumber: number
  numberOfPages: number
  nextPagePath: string
  previousPagePath: string
  classes: any
}

class Pagination extends Component<Props> {
  swapPage = (path: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    if (!!path) {
      navigate(path)
    }
  }

  render() {
    const { pageNumber, numberOfPages, nextPagePath, previousPagePath, classes: c } = this.props
    return (
      <div className={c.PaginationContainer}>
        <ul className={c.PaginationBlock}>
          <li onClick={this.swapPage(previousPagePath)} className={!previousPagePath ? 'disabled' : 'arrow-link'}>
            <img className={c.Arrows} src={arrowLeft} alt="previus page" />
          </li>
          {Array.from({ length: numberOfPages }).map((_item, index) => (
            <Link to={`/blog/${index ? index + 1 : ''}`} activeClassName="active" key={index}>
              <li className={index === pageNumber ? 'numbers active' : 'numbers'}>{index + 1}</li>
            </Link>
          ))}
          <li onClick={this.swapPage(nextPagePath)} className={!nextPagePath ? 'disabled' : 'arrow-link'}>
            <img className={c.Arrows} src={arrowRight} alt="next page" />
          </li>
        </ul>
      </div>
    )
  }
}

const styles = {
  PaginationContainer: {
    textAlign: 'center',
    paddingTop: '24px',
  },
  PaginationBlock: {
    display: 'inline-flex',
    overflow: 'hidden',
    height: '56px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.10)',
    padding: 0,

    '& >li.arrow-link': {
      cursor: 'pointer',
    },

    '& >li.disabled': {
      opacity: 0.3,
    },

    '& a ': {
      textDecoration: 'none',
      color: 'inherit',
    },

    '& li': {
      color: '#999',
      listStyleType: 'none',
      fontSize: '18px',
      lineHeight: '21px',
      borderRight: '1px solid #F0F0F0',

      '&.numbers': {
        padding: '18px 26px',
      },

      '&.active': {
        background: '#579DBC',
        color: '#fff',
      },
    },
  },
  Arrows: {
    width: '24px',
    height: '24px',
    padding: '16px 20px',
  },
}

export default injectSheet(styles)(Pagination)
